/* import __COLOCATED_TEMPLATE__ from './counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Formatters, { units } from 'embercom/lib/reporting/flexible/formatters';
import percent from 'embercom/lib/percentage-formatter';
import countFormatter from 'embercom/lib/count-formatter';
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { cached } from 'tracked-toolbox';
import { isEmpty, isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { SECONDS_IN } from 'embercom/lib/duration-formatter';
import { tracked } from '@glimmer/tracking';

function getValueFromChartData(rawChartData) {
  return rawChartData.aggregations[0].values[0];
}

export default class Counter extends Component {
  @service intl;
  @service appService;

  @tracked error = null;

  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.args.dataConfig,
    viewConfig: this.args.viewConfig,
    onError: (error) => {
      this.error = error.jqXHR?.responseJSON?.error;
    },
    onSuccess: () => {
      this.error = null;
    },
  }));

  get showErrorBanner() {
    return this.error && !this.dataResource.isLoading;
  }

  get app() {
    return this.appService.app;
  }

  get formatter() {
    return new Formatters[this.args.viewConfig?.formatUnit?.unit](
      this.args.viewConfig?.formatUnit?.displayUnit,
    );
  }

  get size() {
    return this.args.size || '1';
  }

  get loadingIndicatorSize() {
    if (this.size === '3') {
      return 'small';
    }
    return 'large';
  }

  get currentCounter() {
    return this.formatter.formatData(this.values.current);
  }

  get previousCounter() {
    return this.formatter.formatData(this.values.previous);
  }

  get improvementDirection() {
    if (isPresent(this.args.renderableChart?.improvementDirection)) {
      return this.args.renderableChart.improvementDirection;
    }

    let improvementDirection = null;
    let names = this.dataResource.rawChartData.map((response) => response.name);

    names.forEach((name) => {
      if (
        this.args.viewConfig.counter &&
        this.args.viewConfig.counter[name]?.improvementDirection
      ) {
        improvementDirection = this.args.viewConfig.counter[name]?.improvementDirection;
        return;
      }
    });

    if (improvementDirection) {
      return improvementDirection;
    } else {
      throw new Error(
        this.intl.t('components.reporting.flexible.comparison-counter.direction-error', {
          names: this.intl.formatList(names, { type: 'conjunction' }),
        }),
      );
    }
  }

  get targetValue() {
    let target = this.args.viewConfig.visualizationOptions?.target?.value;

    return isNaN(target) ? null : target;
  }

  get formattedTargetValue() {
    return this.format(this.targetValue);
  }

  get timeRangeData() {
    return {
      inDays: this.args.range.inDays || 0,
      comparisonStart: this.args.range.comparisonStartMoment,
      comparisonEnd: this.args.range.comparisonEndMoment,
      timezone: this.args.range.timezone,
    };
  }

  get formattedData() {
    if (this.args.showTimeComparison) {
      let formattedCounter;
      if (
        this.args.viewConfig.formatUnit?.unit === units.value &&
        this.args.viewConfig.formatUnit?.commas
      ) {
        formattedCounter = countFormatter(this.values.current);
      } else {
        return this.format(this.values.current);
      }
      return formattedCounter === null ? '-' : formattedCounter;
    } else {
      let value = this.getValueFromDataResponses(this.dataResource.rawChartData);
      return this.format(value);
    }
  }

  @cached
  get values() {
    return this.percentValues || this.ratioValues || this.absoluteValues;
  }

  get percentValues() {
    if (this.args.viewConfig.formatUnit.unit !== units.percent) {
      return;
    }

    if (this.rawValues.length === 2) {
      // covers cases like CSAT where unit is percent but the value is raw (already calculated)
      let [previousValue, currentValue] = this.rawValues;
      return {
        previous: previousValue,
        current: currentValue,
      };
    } else {
      // the order of the series in the data config is important :(
      let [previousNumerator, previousDenominator, currentNumerator, currentDenominator] =
        this.rawValues;
      let previous = percent(previousDenominator, previousNumerator);
      let current = percent(currentDenominator, currentNumerator);
      return { previous, current };
    }
  }

  get ratioValues() {
    if (
      this.metricType !== 'ratio' ||
      this.args.viewConfig.formatUnit.unit !== units.valuePerHour
    ) {
      return;
    }

    // the order of the series in the data config is important :(
    let [previousNumerator, previousDenominator, currentNumerator, currentDenominator] =
      this.rawValues;
    let previous = (previousNumerator * SECONDS_IN.hour) / previousDenominator;
    let current = (currentNumerator * SECONDS_IN.hour) / currentDenominator;

    previous = isNaN(previous) ? null : previous;
    current = isNaN(current) ? null : current;
    return { previous, current };
  }

  get currentNumerator() {
    if (this.args.showTimeComparison) {
      return this.intl.formatNumber(this.rawValues[2]);
    } else {
      return this.intl.formatNumber(this.rawValues[0]);
    }
  }

  get currentDenominator() {
    if (this.args.showTimeComparison) {
      return this.intl.formatNumber(this.rawValues[3]);
    } else {
      return this.intl.formatNumber(this.rawValues[1]);
    }
  }

  get showNumeratorAndDenominator() {
    return (
      this.app.canUseKPIDataLabels &&
      this.args.viewConfig.showDataLabels &&
      !this.dataResource.isLoading &&
      this.metricType === 'percentage'
    );
  }

  get showTarget() {
    return isPresent(this.targetValue) && !this.dataResource.isLoading;
  }

  get absoluteValues() {
    // the order of the series in the data config is important :(
    let [previous, current] = this.rawValues;
    return { previous, current };
  }

  get rawValues() {
    let dataTransformation = this.args.viewConfig.grouping?.dataTransformation;
    if (dataTransformation) {
      return dataTransformation(this.dataResource.rawChartData).map(getValueFromChartData);
    }

    return this.dataResource.rawChartData.map(getValueFromChartData);
  }

  @cached
  get chartData() {
    return this.formattedData;
  }

  get noRawChartData() {
    return this.dataResource.rawChartData.length === 0;
  }

  get valueDescription() {
    return this.args.renderableChart.metric(0).valueDescription;
  }

  get metricType() {
    if (isEmpty(this.args.renderableChart?.chartSeries)) {
      return;
    }
    return this.args.renderableChart.metric(0).type;
  }

  get showEmptyState() {
    return this.args.dashboard?.isPaywalled || this.args.isPaywalled;
  }

  get showSummaryComparison() {
    return (
      this.args.showTimeComparison &&
      isPresent(this.values.current) &&
      isPresent(this.values.previous)
    );
  }

  getValueFromDataResponses(dataResponses) {
    if (this.args.viewConfig?.grouping?.dataTransformation && dataResponses.length > 0) {
      return this.args.viewConfig.grouping.dataTransformation(dataResponses);
    }

    if (this.args.viewConfig.metrics?.[0]?.type === 'ratio' && dataResponses.length === 2) {
      let numerator = dataResponses[0].aggregations[0].values[0];
      let denominator = dataResponses[1].aggregations[0].values[0];
      if (denominator === 0 || isEmpty(denominator) || isEmpty(numerator)) {
        return null;
      }

      if (this.args.viewConfig.metrics?.[0]?.unit === 'valuePerHour') {
        return (numerator * SECONDS_IN.hour) / denominator;
      }
      return numerator / denominator;
    }

    if (this.args.viewConfig.formatUnit?.unit === units.percent && dataResponses.length === 2) {
      let numerator = dataResponses[0].aggregations[0].values[0];
      let denominator = dataResponses[1].aggregations[0].values[0];
      return percent(denominator, numerator);
    }

    return dataResponses?.[0]?.aggregations[0].values[0];
  }

  format(value) {
    let unit = this.args.viewConfig?.formatUnit?.unit;
    if (unit && value !== null) {
      let formatter = new Formatters[unit](this.args.viewConfig?.formatUnit?.displayUnit);
      value = formatter.formatCounter(value);
    }
    if (value === null) {
      return '-';
    }
    return value;
  }
}

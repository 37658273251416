/* import __COLOCATED_TEMPLATE__ from './banner-color-picker.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

interface Signature {
  Args: {
    onSelect: (bgClass: string) => void;
    onDelete: () => void;
    color: string;
  };
}

export default class BannerColorPickerComponent extends Component<Signature> {
  @tracked selectedColor = this.args.color;

  get colors() {
    return [
      { border: 'border-neutral-border', bgClass: 'bg-transparent' },
      { border: 'border-beta-border', bgClass: 'bg-beta-container' },
      { border: 'border-success-border', bgClass: 'bg-success-container' },
      { border: 'border-neutral-border', bgClass: 'bg-neutral-container' },
      { border: 'border-error-border', bgClass: 'bg-error-container' },
      { border: 'border-notice-border', bgClass: 'bg-notice-container' },
    ];
  }

  @action
  handleColorSelect(bgClass: string) {
    this.selectedColor = bgClass;
    this.args.onSelect(bgClass);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartActions::BannerColorPicker': typeof BannerColorPickerComponent;
    'reporting/custom/chart-actions-banner-color-picker': typeof BannerColorPickerComponent;
  }
}

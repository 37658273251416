/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import SeriesOverviewTabConfiguration from 'embercom/objects/series/tabs/series-overview-tab-configuration';
import SeriesReportingTabConfiguration from 'embercom/objects/series/tabs/series-reporting-tab-configuration';
import SeriesStatTabConfiguration from 'embercom/objects/series/tabs/series-stat-tab-configuration';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { and, not, notEmpty, readOnly } from '@ember/object/computed';
import { schedule } from '@ember/runloop';
import { isEmpty, isPresent } from '@ember/utils';
import platform from 'embercom/lib/browser-platform';
import pluralize from 'embercom/lib/inflector';
import { SeriesAnalyticActions, SeriesAnalyticTracker } from 'embercom/lib/series/analytic-tracker';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { MoveNode } from 'embercom/models/graph-editor/action';
import SeriesAnnotation from 'embercom/models/series/annotation';
import ComponentConfiguration from 'embercom/objects/component-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardStatListConfiguration from 'embercom/objects/series/stats/standard-stat-list-configuration';
import { objectNames, objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { exceededElasticSearchLimit } from 'embercom/helpers/matching-system/state-change';

const SHORTCUTS_DISABLED_FOR_TARGETS = [
  'embercom-prosemirror-composer-editor',
  'dhm-input__number',
];

export default class Editor extends Component {
  @service appService;
  @service seriesEditorService;
  @service store;
  @service intercomEventService;
  @service notificationsService;
  @service router;
  @service intl;
  @service intercomConfirmService;
  graph = undefined;
  clipboard = {};

  constructor() {
    super(...arguments);

    this.seriesEditorService.register({
      series: this.args.series,
      mode: this.args.mode,
      view: this.args.view,
      filter: this.args.filter,
      paramsShowSettings: this.args.paramsShowSettings,
      onChangeMode: this.args.onChangeMode,
      onChangeView: this.args.onChangeView,
      onChangeFilter: this.args.onChangeFilter,
      onChangeActiveSeriesNode: this.args.onChangeActiveSeriesNode,
      onChangeParamsShowSettings: this.args.onChangeParamsShowSettings,
    });
    this.graph = this.seriesEditorService.graph;

    if (this.args.initialNodeId) {
      let initialNode = this.args.series.getNodeForId(this.args.initialNodeId);
      if (initialNode) {
        this.seriesEditorService.registerActiveSeriesNode(initialNode);
      }
    }
    this._trackAnalyticsEvent(SeriesAnalyticActions.viewed);
  }

  get app() {
    return this.appService.app;
  }

  get modifierKey() {
    return platform.isMac ? '⌘' : 'ctrl';
  }

  get nodeGraphNodes() {
    return this.graph.nodes.reject((node) => node.dataObject instanceof SeriesAnnotation);
  }

  get annotationGraphNodes() {
    return this.graph.nodes.filter((node) => node.dataObject instanceof SeriesAnnotation);
  }

  get displayESLimitBanner() {
    return exceededElasticSearchLimit(this.args.series?.lastStateChange?.reason);
  }

  get entityName() {
    return objectNames[objectTypes.series];
  }

  @action reloadPage() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'series-has-new-changes-banner',
      context: 'series_editor',
    });
    window.location.reload();
  }

  @action onInsert() {
    schedule('afterRender', this, () => {
      this.seriesEditorService.lastRenderedEdgesAt = new Date();
    });
  }

  @action onDestroy() {
    this.seriesEditorService.unregister(this.args.series);
  }

  @action onEdgeRender() {
    this.seriesEditorService.updateRenderedEdges.perform();
  }

  @action deleteEdge(edge) {
    this.graph.trackActions(() => {
      this.graph.deleteEdge(edge);
      this.seriesEditorService.updateRenderedEdges.perform();
    });
  }

  @action shortcutsHandler(event) {
    if (
      this.seriesEditorService.shortcutsDisabled ||
      // This prevents keyboard shortcuts that behave differently when a particular element is in focus from being picked up by the graph
      // E.g. [CMD+A] while in the annotations composer should select all text in the composer, not all nodes on the graph
      SHORTCUTS_DISABLED_FOR_TARGETS.some((target) => event.target.classList?.contains(target))
    ) {
      return;
    }
    if (this.ctrlCmdKey(event) && event.key === 'd') {
      this.onDuplicateShortcut(event);
    } else if (this.ctrlCmdKey(event) && event.key === 'c') {
      this.onCopyShortcut();
    } else if (this.ctrlCmdKey(event) && event.key === 'v') {
      this.onPasteShortcut();
    } else if (this.ctrlCmdKey(event) && (event.key === '=' || event.key === '+')) {
      this.onZoomInShortcut(event);
    } else if (this.ctrlCmdKey(event) && event.key === '-') {
      this.onZoomOutShortcut(event);
    } else if (this.ctrlCmdKey(event) && event.key === 'a') {
      this.onSelectAllShortcut(event);
    } else if (event.key === 'Delete' || event.key === 'Backspace') {
      this.onDeleteShortcut();
    } else if (['ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp'].includes(event.key)) {
      this.onArrowShortcut(event);
    } else if (event.shiftKey && this.ctrlCmdKey(event) && event.key === 'z') {
      this.onRedo();
    } else if (this.ctrlCmdKey(event) && event.key === 'z') {
      this.onUndo();
    }
  }

  ctrlCmdKey(event) {
    return event.ctrlKey || event.metaKey;
  }

  onDuplicateShortcut(event) {
    event.preventDefault(); // Chrome bookmarks a page on Cmd + d
    if (this.graph.state.selectedNodes.length > 0 && !this.seriesEditorService.activeSeriesNode) {
      if (this.seriesEditorService.isEditMode) {
        this.seriesEditorService.duplicateNodes.perform(this.graph.state.selectedNodes);
      }
    }
  }

  onCopyShortcut() {
    if (!this.seriesEditorService.activeSeriesNode) {
      this.clipboard.nodes = [...this.graph.state.selectedNodes];
    }
  }

  onPasteShortcut() {
    if (this.clipboard.nodes.length > 0 && !this.seriesEditorService.activeSeriesNode) {
      if (this.seriesEditorService.isEditMode) {
        this.seriesEditorService.duplicateNodes.perform(this.clipboard.nodes);
      }
    }
  }

  onZoomOutShortcut(event) {
    if (!this.seriesEditorService.activeSeriesNode) {
      this.graph.state.zoomOut(this.graph.settings.scale.zoomFactor);
      event.preventDefault();
    }
  }

  onZoomInShortcut(event) {
    if (!this.seriesEditorService.activeSeriesNode) {
      this.graph.state.zoomIn(this.graph.settings.scale.zoomFactor);
      event.preventDefault();
    }
  }

  async onUndo() {
    this.graph.undoAction();
  }

  async onRedo() {
    this.graph.redoAction();
  }

  async onDeleteShortcut() {
    if (this.graph.state.selectedEdge && isEmpty(this.graph.state.selectedNodes)) {
      this.graph.trackActions(() => {
        this.graph.deleteEdge(this.graph.state.selectedEdge);
      });
    } else if (
      !this.seriesEditorService.activeSeriesNode &&
      isPresent(this.graph.state.selectedNodes)
    ) {
      let confirmOptions = {
        title: `Delete selected ${pluralize('block', this.graph.state.selectedNodes.length)}`,
        body: `If you save the series, you won't be able to undo this action`,
        primaryButtonType: 'primary-destructive',
        confirmButtonText: `Delete ${pluralize('block', this.graph.state.selectedNodes.length)}`,
      };

      let confirmed = await this.intercomConfirmService.confirm(confirmOptions);

      if (!confirmed) {
        return;
      }

      this.graph.trackActions(() => {
        this.graph.state.selectedNodes.forEach((node) => this.graph.deleteNode(node));
      });
    }
  }

  onSelectAllShortcut(event) {
    if (!this.seriesEditorService.activeSeriesNode) {
      this.graph.state.selectedNodes.clear();
      this.graph.nodes.forEach((node) => this.graph.state.addSelectedNode(node));
      event.preventDefault();
    }
  }

  onArrowShortcut(event) {
    this.graph.trackActions(() => {
      if (!this.seriesEditorService.activeSeriesNode) {
        if (event.key === 'ArrowDown') {
          this._moveSelectedNodes(0, this.graph.settings.grid.spacing);
        } else if (event.key === 'ArrowLeft') {
          this._moveSelectedNodes(-this.graph.settings.grid.spacing, 0);
        } else if (event.key === 'ArrowRight') {
          this._moveSelectedNodes(this.graph.settings.grid.spacing, 0);
        } else if (event.key === 'ArrowUp') {
          this._moveSelectedNodes(0, -this.graph.settings.grid.spacing);
        }
        // Prevent the arrow key event from propagating to the
        // canvas causing it to scroll.
        event.preventDefault();
      }
    });
  }

  get tabsConfiguration() {
    let container = getOwner(this);
    return TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        SeriesOverviewTabConfiguration.create({
          label: this.intl.t('outbound.series.header.tabs.overview.label'),
          subtitle: this.intl.t('outbound.series.header.tabs.overview.subtitle'),
          view: 'overview',
        }),
        SeriesReportingTabConfiguration.extend({
          _isNotDraft: not('seriesEditorService.series.isDraft'),
          _contentTypesUsed: notEmpty('seriesEditorService.series.contentTypesUsed'),
          isVisible: and('_isNotDraft', '_contentTypesUsed'),
        }).create({
          label: this.intl.t('outbound.series.header.tabs.reporting.label'),
          subtitle: this.intl.t('outbound.series.header.tabs.reporting.subtitle'),
          view: 'reporting',
          container,
        }),
        SeriesStatTabConfiguration.create({
          label: this.intl.t('outbound.series.header.tabs.started'),
          view: 'receipts',
          container,
        }),
        SeriesStatTabConfiguration.create({
          label: this.intl.t('outbound.series.header.tabs.finished'),
          view: 'seriesCompletions',
          container,
        }),
        SeriesStatTabConfiguration.create({
          label: this.intl.t('outbound.series.header.tabs.disengaged'),
          view: 'seriesDisengagements',
          container,
        }),
        SeriesStatTabConfiguration.extend({
          isVisible: readOnly('seriesEditorService.series.hasExitRules'),
        }).create({
          label: this.intl.t('outbound.series.header.tabs.exited'),
          view: 'seriesExits',
          container,
        }),
        SeriesStatTabConfiguration.extend({
          isVisible: readOnly('seriesEditorService.series.hasGoal'),
        }).create({
          label: this.intl.t('outbound.series.header.tabs.goal'),
          view: 'goals',
          container,
        }),
      ],
    });
  }

  get activeViewConfiguration() {
    let container = getOwner(this);

    return ActiveViewConfiguration.create({
      overview: [],
      reporting: [
        new ComponentConfiguration({
          component: 'series/editor/overview-report',
          container,
        }),
      ],
      receipts: [
        new StandardStatListConfiguration({
          headerTitleComponent: 'stats-system/list/header/series-started-title',
          stat: 'receipt',
          columns: [
            { label: 'Name', valuePath: 'user.displayAs', type: 'avatar-with-text' },
            { label: 'Where they started', valuePath: 'rulesetId' },
            { label: 'When they started', valuePath: 'createdAt' },
          ],
          noun: 'start',
          verb: 'started',
          emptyStateDescription: "As people start your series, we'll show you when they started",
          container,
        }),
      ],
      seriesCompletions: [
        new StandardStatListConfiguration({ stat: 'seriesCompletion', container }),
      ],
      seriesDisengagements: [
        new StandardStatListConfiguration({ stat: 'seriesDisengagement', container }),
      ],
      seriesExits: [new StandardStatListConfiguration({ stat: 'seriesExit', container })],
      goals: [
        new StandardStatListConfiguration({
          stat: 'goalSuccess',
          filters: [
            { text: 'Hit the goal after finishing', value: stats.seriesCompletion },
            { text: 'Hit the goal after disengaging', value: stats.seriesDisengagement },
            { text: 'Hit the goal after exiting', value: stats.seriesExit },
          ],
          container,
        }),
      ],
    });
  }

  get activeSeriesOverviewStatConfig() {
    let activeSeriesOverviewStatKey = this.seriesEditorService.activeSeriesOverviewStat;
    let activeSeriesOverviewStatName = Object.keys(statisticKeys).find(
      (key) => statisticKeys[key] === activeSeriesOverviewStatKey,
    );

    return this.activeViewConfiguration[activeSeriesOverviewStatName][0];
  }

  _moveSelectedNodes(x, y) {
    this.graph.state.selectedNodes.forEach((node) => {
      node.position.x += x;
      node.position.y += y;
      node.calculateBounds();
      this.graph.hooks.didUpdateNode(node);
      this.graph.recordAction(new MoveNode(node, x, y));
    });
  }

  _trackAnalyticsEvent(action) {
    new SeriesAnalyticTracker({
      intercomEventService: this.intercomEventService,
      seriesEditorService: this.seriesEditorService,
    }).track({ action });
  }

  @action transitionToSeriesIndex() {
    this.router.transitionTo('apps.app.outbound.series.index');
  }

  get helpLinks() {
    return [
      {
        items: [
          {
            component: 'series/editor/help-tour',
          },
          {
            component: 'series/editor/help-article',
          },
        ],
      },
    ];
  }
}

/* import __COLOCATED_TEMPLATE__ from './whats-new-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type IntlService from 'ember-intl/services/intl';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

interface Args {}

export default class WhatsNewDropdown extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  get linkItems() {
    return [
      {
        text: this.intl.t('reporting.custom-reports.learn.new-metrics-attributes.title'),
        onSelectItem: () => {
          let url =
            'https://www.intercom.com/help/en/articles/8986025-what-s-new-in-reports#h_a3e74d8ce1';
          this.trackAnalyticsEvent(url);
          safeWindowOpen(url);
        },
        component: 'reporting/custom/whats-new-dropdown-item',
        description: this.intl.t(
          'reporting.custom-reports.learn.new-metrics-attributes.description',
        ),
        icon: 'sla' as InterfaceIconName,
      },
      {
        text: this.intl.t('reporting.custom-reports.learn.multimetric-charts-title'),
        onSelectItem: () => {
          let url =
            'https://www.intercom.com/help/en/articles/8986025-what-s-new-in-reports#h_2ed06d2df9';
          this.trackAnalyticsEvent(url);
          safeWindowOpen(url);
        },
        component: 'reporting/custom/whats-new-dropdown-item',
        description: this.intl.t('reporting.custom-reports.learn.multimetric-charts-description'),
        icon: 'combo-chart' as InterfaceIconName,
      },
      {
        text: this.intl.t('reporting.custom-reports.learn.chart-definition-tooltip-title'),
        onSelectItem: () => {
          let url =
            'https://www.intercom.com/help/en/articles/8986025-what-s-new-in-reports#h_e5cb10c060';
          this.trackAnalyticsEvent(url);
          safeWindowOpen(url);
        },
        component: 'reporting/custom/whats-new-dropdown-item',
        description: this.intl.t(
          'reporting.custom-reports.learn.chart-definition-tooltip-description',
        ),
        icon: 'tooltip' as InterfaceIconName,
      },
      {
        text: this.intl.t('reporting.custom-reports.learn.flexible-navigation-title'),
        onSelectItem: () => {
          let url =
            'https://www.intercom.com/help/en/articles/8986025-what-s-new-in-reports#h_e4f6e11cd0';
          this.trackAnalyticsEvent(url);
          safeWindowOpen(url);
        },
        component: 'reporting/custom/whats-new-dropdown-item',
        description: this.intl.t('reporting.custom-reports.learn.flexible-navigation-description'),
        icon: 'folder' as InterfaceIconName,
      },
      {
        text: this.intl.t('reporting.custom-reports.learn.new-chart-types-title'),
        onSelectItem: () => {
          let url =
            'https://www.intercom.com/help/en/articles/8986025-what-s-new-in-reports#h_f0dd78f266';
          this.trackAnalyticsEvent(url);
          safeWindowOpen(url);
        },
        component: 'reporting/custom/whats-new-dropdown-item',
        description: this.intl.t('reporting.custom-reports.learn.new-chart-types-description'),
        icon: 'column-chart' as InterfaceIconName,
      },
    ];
  }

  get whatsNewLinks() {
    return [
      {
        heading: this.intl.t('reporting.submenu-component.new-features.modal-title'),
        items: this.linkItems,
      },
      {
        items: [
          {
            component: 'reporting/custom/whats-new-dropdown-item-survey-link',
            componentShouldReplaceItem: true,
          },
        ],
      },
    ];
  }

  private trackAnalyticsEvent(url: string) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'reporting_whats_new',
      action: 'link_clicked',
      url,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::WhatsNewDropdown': typeof WhatsNewDropdown;
    'reporting/custom/whats-new-dropdown': typeof WhatsNewDropdown;
  }
}

/* import __COLOCATED_TEMPLATE__ from './overview-report.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import PALETTE from '@intercom/pulse/lib/palette';
import { filterableProperties } from 'embercom/lib/reporting/custom/filter-helpers';
import { type SerializedChartSeries } from 'embercom/components/reporting/in-context/static-report';
import {
  ConversationFunnelConfig,
  wrap,
} from 'embercom/components/reporting/bespoke/overview/conversation-funnel/series-config';
import { SANKEY_COLORS } from 'embercom/components/reporting/bespoke/overview/conversation-funnel';
import type RouterService from '@ember/routing/router-service';
import type { Action } from 'embercom/components/common/hero-banner';

const DEFAULT_CHART_DEFINITION = {
  id: null,
  title: null,
  visualization_type: VISUALIZATION_TYPES.COUNTER,
  visualization_options: {
    show_time_comparison: false,
  },
  metric_property: null,
  metric_function: null,
  stacked: false,
  view_by: null,
  view_by_time_interval: null,
  view_by_display_limit: 10,
  view_by_display_other: false,
  width_type: null,
  height_type: null,
};

interface Args {
  conversationAttributeDescriptors: ConversationAttributeDescriptor[];
  hasDismissedAwarenessBanner: boolean;
  awarenessBannerKey: string;
  hasDismissedMigrationBanner: boolean;
  migrationBannerKey: string;
}

export default class OverviewReport extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare router: RouterService;

  get reportDefinition() {
    return {
      id: `overview_report`,
      title: this.intl.t('reporting.overview.title'),
      description: null,
      charts: [],
      date_range: {
        selection: 'past_12_weeks',
      },
      filters: {},
    };
  }

  get newHeroBannerActions(): Action[] {
    return [
      {
        type: 'link',
        label: 'reporting.overview.new-banner.actions.article-reports-explained',
        icon: 'article',
        analyticsId: 'reports-explained-article',
        onClick: () => {
          window.Intercom('showArticle', 200); // https://www.intercom.com/help/en/articles/200-intercom-reports-explained
        },
      },
      {
        type: 'link',
        label: 'reporting.overview.new-banner.actions.article-understanding-reporting-datasets',
        icon: 'article',
        analyticsId: 'understanding-reporting-datasets-article',
        onClick: () => {
          window.Intercom('showArticle', 8825260); // https://www.intercom.com/help/en/articles/8825260-understanding-reporting-datasets
        },
      },
      {
        type: 'link',
        label: 'reporting.overview.new-banner.actions.article-create-a-custom-report',
        icon: 'article',
        analyticsId: 'create-a-custom-report-article',
        onClick: () => {
          window.Intercom('showArticle', 4549035); // https://www.intercom.com/help/en/articles/4549035-create-a-custom-report
        },
      },
    ];
  }

  chartDefinition(properties: Record<string, unknown> & { id: string }) {
    let chartSeries = properties.chart_series as Array<SerializedChartSeries>;
    return {
      ...DEFAULT_CHART_DEFINITION,
      ...this.titleAndTooltipFromChartSeries(chartSeries[0]),
      ...properties,
    };
  }

  titleAndTooltipFromChartSeries(chartSeries: SerializedChartSeries) {
    let metricId = chartSeries.metric_id;
    let metric = this.reportingMetrics.getMetricById(metricId);
    if (!metric) {
      return {};
    }

    return {
      title: metric.name,
      tooltip: sanitizeHtml(
        `${metric.description}  ${this.intl.t('reporting.copilot.more-details')}`,
      ),
    };
  }

  get csatSectionCharts() {
    return [
      this.chartDefinition({
        id: 'overview.csat-over-time',
        title: this.intl.t('reporting.overview.charts.csat-over-time.title'),
        description: this.intl.t('reporting.overview.charts.csat-over-time.description'),
        tooltip: null,
        chart_series: [
          { metric_id: 'conversation_rating.human.csat', color: PALETTE['vis-yellow-50'] },
          { metric_id: 'conversation_rating.ai_agent.csat', color: PALETTE['vis-mint-50'] },
        ],
        view_by: 'time',
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.FULL,
      }),
      this.chartDefinition({
        id: 'overview.topics-driving-bad-ratings',
        title: this.intl.t('reporting.overview.charts.topics-driving-bad-ratings.title'),
        description: this.intl.t(
          'reporting.overview.charts.topics-driving-bad-ratings.description',
        ),
        tooltip: null,
        chart_series: this.topicChartSeries,
        view_by: 'topic',
        visualization_type: VISUALIZATION_TYPES.HORIZONTAL_BAR,
        width_type: WIDTH_TYPES.FULL,
        footer_text: 'reporting.overview.charts.most-common-topics.footer-text',
        footer_cta_component: 'reporting/overview/most-common-topics-footer-cta',
      }),
    ];
  }

  get topicChartSeries() {
    if (this.appService.app.canUseCsatV2) {
      return [
        {
          metric_id: 'conversation_rating.any_agent.count',
          filters: {
            type: 'and',
            filters: [
              {
                type: 'category',
                data: {
                  property: 'rating_index',
                  attribute: 'conversation_rating_sent.rating_index',
                  values: ['1', '2', '3'],
                },
              },
            ],
          },
        },
      ];
    } else {
      return [
        {
          metric_id: 'v1.new_conversations',
          filters: {
            type: 'or',
            filters: [
              {
                type: 'category',
                data: {
                  property: 'conversation_rating.rating_index',
                  attribute: 'conversation.conversation_rating.rating_index',
                  values: ['1', '2'],
                },
              },
              {
                type: 'category',
                data: {
                  property: 'bot_conversation_rating.rating_index',
                  attribute: 'conversation.bot_conversation_rating.rating_index',
                  values: ['1', '2'],
                },
              },
              {
                type: 'category',
                data: {
                  property: 'workflow_conversation_rating.rating_index',
                  attribute: 'conversation.workflow_conversation_rating.rating_index',
                  values: ['1', '2'],
                },
              },
            ],
          },
        },
      ];
    }
  }

  get bannerChart() {
    if (this.appService.app.canSeeFullR2Experience) {
      return [];
    } else {
      return [
        {
          id: 'overview.banner',
          title: null,
          description: sanitizeHtml(
            this.intl.t('reporting.overview.banner', {
              htmlSafe: true,
              previous: this.router.urlFor('apps.app.reports.old-overview'),
              article: 'https://www.intercom.com/help/en/articles/3008200-overview-report',
            }),
          ).string,
          chart_series: [],
          visualization_type: VISUALIZATION_TYPES.BANNER,
          height_type: HEIGHT_TYPES.TINY,
          width_type: WIDTH_TYPES.FULL,
        },
      ];
    }
  }

  get conversationSupportFunnelSectionChartsWithSankey() {
    return [
      this.chartDefinition({
        id: 'overview.handling.funnel',
        title: this.intl.t('reporting.overview.charts.handling.funnel.title'),
        description: this.intl.t('reporting.overview.charts.handling.funnel.description'),
        chart_series: ConversationFunnelConfig.getChartSeriesWithLabels(this.intl),
        width_type: WIDTH_TYPES.FULL,
        height_type: HEIGHT_TYPES.X_TALL,
        visualization_type: VISUALIZATION_TYPES.BESPOKE,
        component_name: 'overview/conversation-funnel',
      }),

      this.chartDefinition({
        id: 'overview.handling.volume-growth',
        title: this.intl.t('reporting.overview.charts.volume-growth.title'),
        description: this.intl.t('reporting.overview.charts.volume-growth.description'),
        chart_series: [
          {
            label: this.intl.t('reporting.overview.charts.volume-growth.series-labels.all'),
            metric_id: 'v1.new_conversations',
            color: SANKEY_COLORS.conversations,
          },
          {
            label: this.intl.t('reporting.overview.charts.volume-growth.series-labels.fin'),
            metric_id: 'v1.new_conversations',
            filters: wrap(ConversationFunnelConfig.filters.finWasInvolvedAndSentAnAnswer),
            color: SANKEY_COLORS.fin,
          },
          {
            label: this.intl.t('reporting.overview.charts.volume-growth.series-labels.chatbot'),
            metric_id: 'v1.new_conversations',
            filters: wrap(ConversationFunnelConfig.filters.chatbotWasOnlyInvolved),
            color: SANKEY_COLORS.chatbot,
          },
          {
            label: this.intl.t('reporting.overview.charts.volume-growth.series-labels.teammate'),
            metric_id: 'v1.new_conversations',
            filters: wrap(ConversationFunnelConfig.filters.teammateWasEverInvolved),
            color: SANKEY_COLORS.teammate,
          },
          {
            label: this.intl.t('reporting.overview.charts.volume-growth.series-labels.no-reply'),
            metric_id: 'v1.new_conversations',
            filters: wrap(ConversationFunnelConfig.filters.noReply),
            color: SANKEY_COLORS.gray,
          },
        ],
        view_by: 'time',
        width_type: WIDTH_TYPES.FULL,
        visualization_type: VISUALIZATION_TYPES.AREA,
      }),

      ...this.channelCharts,
    ];
  }

  get channelCharts() {
    return [
      this.chartDefinition({
        id: 'overview.new-conversations-by-channel',
        title: this.intl.t('reporting.overview.charts.new-conversations-by-channel.title'),
        description: this.intl.t(
          'reporting.overview.charts.new-conversations-by-channel.description',
        ),
        tooltip: null,
        chart_series: [{ metric_id: 'v1.new_conversations' }],
        view_by: 'time',
        segment_by: 'channel_type',

        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.THREE_COLUMN,
      }),
      this.chartDefinition({
        id: 'overview.median-time-to-close-by-channel',
        title: this.intl.t('reporting.overview.charts.median-time-to-close-by-channel.title'),
        description: this.intl.t(
          'reporting.overview.charts.median-time-to-close-by-channel.description',
        ),
        chart_series: [{ metric_id: 'v2.time_to_close.bot_inbox_included', aggregation: 'median' }],
        segment_by: 'channel_type',
        view_by: 'time',

        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.THREE_COLUMN,
      }),
    ];
  }

  get finAiAgentPerformanceSectionCharts() {
    return [
      // Row 1

      // fin involvement
      this.chartDefinition({
        id: 'overview.fin-involvement',
        title: this.intl.t('reporting.overview.charts.fin.involvement.title'),
        description: this.intl.t('reporting.overview.charts.fin.involvement.description'),
        chart_series: [{ metric_id: 'fin.participation_rate' }],
        visualization_options: {
          show_time_comparison: true,
        },
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
      }),

      // answer rate
      this.chartDefinition({
        id: 'overview.fin-answer-rate',
        title: this.intl.t('reporting.overview.charts.fin.answer-rate.title'),
        description: this.intl.t('reporting.overview.charts.fin.answer-rate.description'),
        chart_series: [{ metric_id: 'fin.answer_rate' }],
        visualization_options: {
          show_time_comparison: true,
        },
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
      }),

      // deflection rate
      this.chartDefinition({
        id: 'overview.fin-deflection-rate',
        title: this.intl.t('reporting.overview.charts.fin.deflection-rate.title'),
        description: this.intl.t('reporting.overview.charts.fin.deflection-rate.description'),
        chart_series: [{ metric_id: 'fin.deflection_rate' }],
        visualization_options: {
          show_time_comparison: true,
        },
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
      }),

      // resolution rate
      this.chartDefinition({
        id: 'overview.fin-resolution-rate',
        title: this.intl.t('reporting.overview.charts.fin.resolution-rate.title'),
        description: this.intl.t('reporting.overview.charts.fin.resolution-rate.description'),
        chart_series: [{ metric_id: 'fin.resolution_rate' }],
        visualization_options: {
          show_time_comparison: true,
        },
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
      }),

      // Row 2
      this.chartDefinition({
        id: 'overview.fin-impact-over-time',
        title: this.intl.t('reporting.overview.charts.fin.impact-over-time.title'),
        description: this.intl.t('reporting.overview.charts.fin.impact-over-time.description'),
        tooltip: null,
        chart_series: [
          {
            metric_id: 'fin.participation_rate',
            label: this.intl.t('reporting.overview.charts.fin.involvement.title'),
            color: PALETTE['vis-yellow-40'],
          },
          {
            metric_id: 'fin.answer_rate',
            label: this.intl.t('reporting.overview.charts.fin.answer-rate.title'),
            color: PALETTE['vis-slateblue-40'],
          },
          {
            metric_id: 'fin.deflection_rate',
            label: this.intl.t('reporting.overview.charts.fin.deflection-rate.title'),
            color: PALETTE['vis-salmon-40'],
          },
          {
            metric_id: 'fin.resolution_rate',
            label: this.intl.t('reporting.overview.charts.fin.resolution-rate.title'),
            color: PALETTE['vis-mint-40'],
          },
        ],
        view_by: 'time',
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.FULL,
        footer_text: 'reporting.fin.impact-over-time.best-pratices-summary',
        footer_cta_component: 'reporting/reports/fin/impact-over-time-cta-link',
      }),

      // Row 3
      this.chartDefinition({
        id: 'overview.fin-impact-over-time-count',
        title: this.intl.t('reporting.overview.charts.fin.impact-over-time-count.title'),
        description: this.intl.t(
          'reporting.overview.charts.fin.impact-over-time-count.description',
        ),
        chart_series: [
          {
            metric_id: 'v1.new_conversations',
            label: this.intl.t('reporting.overview.charts.fin.all-conversations.title'),
            color: PALETTE['vis-blue-40'],
          },
          {
            metric_id: 'fin.conversations_participated.count',
            label: this.intl.t('reporting.overview.charts.fin.participated-count.title'),
            color: PALETTE['vis-yellow-40'],
          },
          {
            metric_id: 'fin.conversations_answered.count',
            label: this.intl.t('reporting.overview.charts.fin.answered-count.title'),
            color: PALETTE['vis-slateblue-40'],
          },
          {
            metric_id: 'fin.conversations_deflected.count',
            label: this.intl.t('reporting.overview.charts.fin.deflected-count.title'),
            color: PALETTE['vis-salmon-40'],
          },
          {
            metric_id: 'fin.conversations_resolved.count',
            label: this.intl.t('reporting.overview.charts.fin.resolution-count.title'),
            color: PALETTE['vis-mint-40'],
          },
        ],
        view_by: 'time',
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.FULL,
      }),
    ];
  }

  get teamEfficiencySectionCharts() {
    return [
      // Row 1
      this.chartDefinition({
        id: 'overview.first-response-time',
        title: this.intl.t('reporting.overview.charts.median-first-response-time.title'),
        description: this.intl.t(
          'reporting.overview.charts.median-first-response-time.description',
        ),
        chart_series: [
          { metric_id: 'v2.first_response_time.bot_inbox_included', aggregation: 'median' },
        ],
        visualization_options: {
          show_time_comparison: true,
        },
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
      }),
      this.chartDefinition({
        id: 'overview.median-response-time',
        title: this.intl.t('reporting.overview.charts.median-response-time.title'),
        description: this.intl.t('reporting.overview.charts.median-response-time.description'),
        chart_series: [{ metric_id: 'v2.response_time.comment_created_at', aggregation: 'median' }],
        visualization_options: {
          show_time_comparison: true,
        },
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
      }),
      this.chartDefinition({
        id: 'overview.median-time-to-close',
        title: this.intl.t('reporting.overview.charts.median-time-to-close.title'),
        description: this.intl.t('reporting.overview.charts.median-time-to-close.description'),
        chart_series: [{ metric_id: 'v2.time_to_close.bot_inbox_included', aggregation: 'median' }],
        visualization_options: {
          show_time_comparison: true,
        },
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
      }),
      this.chartDefinition({
        id: 'overview.median-conversation-handling-time',
        title: this.intl.t('reporting.overview.charts.median-conversation-handling-time.title'),
        description: this.intl.t(
          'reporting.overview.charts.median-conversation-handling-time.description',
        ),
        chart_series: [
          {
            metric_id: 'v1.handling_time',
            aggregation: 'median',
            // ignore conversations where no teammate was involved since these can have very short handling times
            filters: wrap(ConversationFunnelConfig.oldFilters.handledByTeammate),
          },
        ],
        visualization_options: {
          show_time_comparison: true,
        },
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
      }),

      // Row 2
      this.chartDefinition({
        id: 'overview.teammate-performance-over-time',
        title: this.intl.t('reporting.overview.charts.teammate-performance-over-time.title'),
        description: this.intl.t(
          'reporting.overview.charts.teammate-performance-over-time.description',
        ),
        tooltip: null,
        chart_series: [
          {
            metric_id: 'v2.first_response_time.bot_inbox_included',
            aggregation: 'median',
          },
          {
            metric_id: 'v2.response_time.comment_created_at',
            aggregation: 'median',
          },
          {
            metric_id: 'v2.time_to_close.bot_inbox_included',
            aggregation: 'median',
          },
          {
            metric_id: 'v1.handling_time',
            aggregation: 'median',
            // ignore conversations where no teammate was involved since these can have very short handling times
            filters: wrap(ConversationFunnelConfig.oldFilters.handledByTeammate),
          },
        ],
        view_by: 'time',
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.FULL,
      }),
    ];
  }

  get filterableProperties() {
    return [...filterableProperties(), 'teammate', 'team', 'conversation.replied.workflow_ids'];
  }

  get aiCsatSectionCharts() {
    let aiCsatScoreOverall = {
      metric_id: 'conversation.ai_generated_metrics.csat_score',
      label: this.intl.t('reporting.overview.charts.ai-generated.csat-overall.title'),
      color: SANKEY_COLORS.conversations,
    };
    let aiCsatScoreTeammate = {
      metric_id: 'conversation.ai_generated_metrics.csat_score',
      // these filters and colors are chosen to be consistent with those in the Overall volume growth chart
      filters: wrap(ConversationFunnelConfig.filters.teammateWasEverInvolved),
      label: this.intl.t('reporting.overview.charts.ai-generated.csat-teammate.title'),
      color: SANKEY_COLORS.teammate,
    };
    let aiCsatScoreFin = {
      metric_id: 'conversation.ai_generated_metrics.csat_score',
      // these filters and colors are chosen to be consistent with those in the Overall volume growth chart
      label: this.intl.t('reporting.overview.charts.ai-generated.csat-fin.title'),
      filters: wrap(ConversationFunnelConfig.filters.finWasInvolvedAndSentAnAnswer),
      color: SANKEY_COLORS.fin,
    };
    let aiCsatScoreChatbot = {
      metric_id: 'conversation.ai_generated_metrics.csat_score',
      label: this.intl.t('reporting.overview.charts.ai-generated.csat-chatbot.title'),
      filters: wrap(ConversationFunnelConfig.filters.chatbotWasOnlyInvolved),
      color: SANKEY_COLORS.chatbot,
    };

    return [
      // row 1

      // AI Generated CSAT score KPI
      this.chartDefinition({
        id: 'ai_generated.csat_overall',
        title: this.intl.t('reporting.overview.charts.ai-generated.csat-overall.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [aiCsatScoreOverall],
      }),

      // Teammate AI CSAT score KPI
      this.chartDefinition({
        id: 'ai_generated.csat_teammate',
        title: this.intl.t('reporting.overview.charts.ai-generated.csat-teammate.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [aiCsatScoreTeammate],
      }),

      // Fin AI Agent AI CSAT score KPI
      this.chartDefinition({
        id: 'ai_generated.csat_fin',
        title: this.intl.t('reporting.overview.charts.ai-generated.csat-fin.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [aiCsatScoreFin],
      }),

      // Chatbot AI CSAT score KPI
      this.chartDefinition({
        id: 'ai_generated.csat_chatbot',
        title: this.intl.t('reporting.overview.charts.ai-generated.csat-chatbot.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [aiCsatScoreChatbot],
      }),

      // row 2

      // Surveyed CSAT score KPI
      this.chartDefinition({
        id: 'ai_generated.csat_overall',
        title: this.intl.t('reporting.overview.charts.surveyed.csat-overall.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'conversation_rating.any_agent.csat',
          },
        ],
      }),

      // Teammate Surveyed CSAT score KPI
      this.chartDefinition({
        id: 'ai_generated.csat_teammate',
        title: this.intl.t('reporting.overview.charts.surveyed.csat-teammate.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'conversation_rating.human.csat',
          },
        ],
      }),

      // Fin Surveyed CSAT score KPI
      this.chartDefinition({
        id: 'ai_generated.csat_fin',
        title: this.intl.t('reporting.overview.charts.surveyed.csat-fin.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'conversation_rating.ai_agent.csat',
          },
        ],
      }),

      // Chatbot Surveyed CSAT score KPI
      this.chartDefinition({
        id: 'ai_generated.csat_chatbot',
        title: this.intl.t('reporting.overview.charts.surveyed.csat-chatbot.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.ONE_QUARTER,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'conversation_rating.workflows.csat',
          },
        ],
      }),

      // row 3

      // AI Generated CSAT score over time
      this.chartDefinition({
        id: 'ai_generated.csat_over_time',
        title: this.intl.t('reporting.overview.charts.ai-generated.csat-over-time.title'),
        chart_series: [aiCsatScoreOverall, aiCsatScoreTeammate, aiCsatScoreFin],
        view_by: 'time',
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.FULL,
      }),

      //row 3

      // AI generated DSAT score over time
      this.chartDefinition({
        id: 'ai_generated.dsat-over-time',
        title: this.intl.t('reporting.overview.charts.ai-generated.dsat-over-time.title'),
        chart_series: [{ metric_id: 'conversation.ai_generated_metrics.dsat_score' }],
        view_by: 'time',
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.THREE_COLUMN,
      }),

      // Negatively rated conversations
      this.chartDefinition({
        id: 'ai_generated.dsat-over-time',
        chart_series: [
          // TODO: resolve difference...
          { metric_id: 'conversation.ai_generated_metrics.csat.negatively_rated.count' },
        ],
        view_by: 'time',
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.THREE_COLUMN,
      }),

      // Topics driving negative ratings
      this.chartDefinition({
        id: 'ai_generated.topics-driving-negative-ratings',
        title: this.intl.t(
          'reporting.overview.charts.ai-generated.topics-driving-negative-ratings.title',
        ),
        chart_series: [
          {
            metric_id: 'v1.new_conversations',
            filters: {
              type: 'and',
              filters: [
                {
                  type: 'category',
                  data: {
                    property: 'ai_generated_metrics.csat',
                    attribute: 'conversation.ai_generated_metrics.csat',
                    values: ['1', '2'],
                  },
                },
              ],
            },
          },
        ],
        view_by: 'topic',
        visualization_type: VISUALIZATION_TYPES.HORIZONTAL_BAR,
        width_type: WIDTH_TYPES.FULL,
      }),
    ];
  }

  get canSeeMigrationBanner() {
    return !this.appService.app.canSeeFullR2Experience;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Overview::OverviewReport': typeof OverviewReport;
  }
}

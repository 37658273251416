/* import __COLOCATED_TEMPLATE__ from './topic-card.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import { type TopicItem } from './topics-side-panel';
import Component from '@glimmer/component';

interface Args {
  topicItem: TopicItem;
  onSelect: (topic: TopicItem) => void;
}

export default class TopicCard extends Component<Args> {
  get numberOfConversations() {
    return this.args.topicItem.conversationIds.size;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::TopicCard': typeof TopicCard;
    'reporting/automation/ai-insights/topic-card': typeof TopicCard;
  }
}

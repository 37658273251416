/* import __COLOCATED_TEMPLATE__ from './topics-side-panel.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import Component from '@glimmer/component';
import AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';
import { EntityType } from 'embercom/models/data/entity-types';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { restartableTask } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import type ContentSuggestionsService from 'embercom/services/content-suggestions-service';
import { put } from 'embercom/lib/ajax';
import type IntlService from 'ember-intl/services/intl';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import { ReviewType } from 'embercom/models/content-service/content-review-request';

export type TopicItem = {
  topic: string;
  count: number;
  items: Array<ContentWrapper>;
  conversationIds: Set<number>;
};
type ReviewAction = 'approve' | 'reject';

interface Args {
  showSidePanel: boolean;
  toggleSidePanel: () => void;
}

export default class TopicsSidePanel extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare contentSuggestionsService: ContentSuggestionsService;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare permissionsService: $TSFixMe;

  @tracked loadTopicSuggestionsApi?: AiContentLibraryApi;
  @tracked selectedTopic?: TopicItem;
  @tracked showSuggestionsSideDrawer = false;
  @tracked declare listApi?: AiContentLibraryApi;

  constructor(owner: any, args: Args) {
    super(owner, args);
    taskFor(this.loadSuggestions).perform();
  }

  get selectedTopicItems() {
    return this.contentSuggestionsService.selectedTopicItems;
  }

  get topics() {
    return this.contentSuggestionsService.topics;
  }

  get isLoadingSuggestions() {
    return taskFor(this.loadSuggestions).isRunning;
  }

  get isLoadingTopicSuggestions() {
    return taskFor(this.loadTopicSuggestions).isRunning;
  }

  @action
  suggestionType(item: ContentWrapper) {
    let objectName = objectNames[item.entityType];

    if (item.contentReviewType === ReviewType.CREATE_CONTENT) {
      return this.intl.t(`knowledge-hub.content-editor.drawer-editor.titles.add-${objectName}`);
    } else if (item.contentReviewType === ReviewType.EDIT_CONTENT) {
      return this.intl.t(`knowledge-hub.content-editor.drawer-editor.titles.edit-${objectName}`);
    } else if (item.contentReviewType === ReviewType.DELETE_CONTENT) {
      return this.intl.t(`knowledge-hub.content-editor.drawer-editor.titles.delete-${objectName}`);
    }
    return this.intl.t(
      `knowledge-hub.content-editor.drawer-editor.titles.fallback-title-${objectName}`,
    );
  }

  @action
  numberOfConversations(item: ContentWrapper) {
    let count = item.contentReviewSuggestionMetadata.included_conversations?.length || 0;
    return this.intl.t('automation.ai-insights.topics-side-panel.conversation-count', {
      count,
    });
  }

  @restartableTask private *loadSuggestions() {
    let api = new AiContentLibraryApi(getOwner(this), {
      object_types: [EntityType.ArticleContent, EntityType.ContentSnippet],
      content_wrapper_types: [ContentWrapperType.AI_CONTENT],
      app_id: this.appService.app.id as string,
      per_page: 100,
      content_review_statuses: [Status.Pending],
      title: '',
    });

    yield api.loadPage();
    this.contentSuggestionsService.listApi = api;
    this.listApi = api;
    this.contentSuggestionsService.updateTopicList();
  }

  @restartableTask private *loadTopicSuggestions(topicItem: TopicItem) {
    this.loadTopicSuggestionsApi = new AiContentLibraryApi(getOwner(this), {
      object_types: [EntityType.ArticleContent, EntityType.ContentSnippet],
      content_wrapper_types: [ContentWrapperType.AI_CONTENT],
      app_id: this.appService.app.id as string,
      per_page: 100,
      content_review_statuses: [Status.Pending],
      additional_searchable_data: {
        topic: topicItem.topic,
      },
    });

    yield this.loadTopicSuggestionsApi.loadPage();
    let items = this.loadTopicSuggestionsApi.contentWrappers.map((wrapper) => wrapper);
    topicItem.items = items;
  }

  @action
  selectTopic(topicItem: TopicItem) {
    this.selectedTopic = topicItem;
    this.contentSuggestionsService.selectedTopicItems = topicItem.items.sort((a, b) => {
      let aCount = a.contentReviewSuggestionMetadata.included_conversations?.length || 0;
      let bCount = b.contentReviewSuggestionMetadata.included_conversations?.length || 0;
      return bCount - aCount;
    });
    this.showSuggestionsSideDrawer = true;
    taskFor(this.loadTopicSuggestions).perform(topicItem);
  }

  @action
  editVersion(item: ContentWrapper) {
    this.knowledgeHubDrawerEditorService.openReviewDrawer({
      activeContentId: item.entityId!,
      activeContentType: objectNames[item.entityType],
    });
  }

  @action
  stopEditing() {
    this.selectedTopic = undefined;
  }

  @action
  rejectVersion(item: ContentWrapper) {
    taskFor(this.bulkReview).perform('reject', {
      entity_id: item.entityId!,
      entity_type: item.entityType,
    });
  }

  @restartableTask private *bulkReview(
    action: ReviewAction,
    contents: { entity_id: number; entity_type: EntityType },
  ) {
    try {
      yield this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    let actionUrl = {
      approve: 'bulk_approve_suggestion',
      reject: 'bulk_reject_suggestion',
    };

    try {
      yield put(`/ember/content_service/contents/${actionUrl[action]}`, {
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
        contents_to_review: [contents],
        is_bulk_selection: false,
      });

      this.contentSuggestionsService.removeSuggestionFromList(contents);
      this.notificationsService.notifyConfirmation(
        this.intl.t(`automation.ai-insights.suggestions.${action}-success`),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t(`knowledge-hub.suggestion-review.error.${action}`),
      );
    }
    this.contentSuggestionsService.updateTopicList();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::TopicsSidePanel': typeof TopicsSidePanel;
  }
}

/* import __COLOCATED_TEMPLATE__ from './add-chart-side-panel.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ReportingTemplates from 'embercom/services/reporting-templates';
import type Report from 'embercom/models/reporting/custom/report';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { elementIsVisibleOnScreen } from 'embercom/lib/screen-awareness';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import { GridStack } from 'gridstack';
import { handleChartTemplateDragEvent } from 'embercom/lib/reporting/custom/draggable-chart-template-helper';
import type ReportingStandalone from 'embercom/services/reporting-standalone';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  report: Report;
  addChart: (buttonPosition: string) => Promise<void>;
  isStandalone: boolean;
}

export default class AddChartSidePanel extends Component<Args> {
  @service declare reportingTemplates: ReportingTemplates;
  @service declare intl: IntlService;
  @service declare reportingStandalone: ReportingStandalone;
  @service declare appService: any;

  @tracked searchTerm = '';
  @tracked isHidden = false;
  @tracked selectedChartType = 'any';
  @tracked selectedReportType = 'any';

  get filteredTemplates() {
    return this.reportingTemplates.searchChartsWithFilters(
      this.searchTerm,
      this.selectedChartType,
      this.selectedReportType,
      this.args.isStandalone,
    );
  }

  get emptyTemplates() {
    return this.filteredTemplates.every((report: any) => {
      return report.chartTemplates.length === 0;
    });
  }

  @action
  clearFilters() {
    this.searchTerm = '';
    this.selectedChartType = 'any';
    this.selectedReportType = 'any';
  }

  @action
  checkCreateChartCard() {
    debounce(this, this.isCreateChartVisible, ENV.APP._100MS);
  }

  isCreateChartVisible() {
    if (!this.shouldShowCreateNewChart) {
      return;
    }
    let element = document.getElementById('create-new-chart-card');
    this.isHidden = !elementIsVisibleOnScreen(element);
  }

  @action
  async createNewChart() {
    await this.args.addChart('chart-template-side-panel');
  }

  @action
  renderChartTemplateDragIn() {
    let parent = document.querySelector('div[data-shared-report]');
    let siblingComponent = parent?.querySelector('div[chart-grid-layout]');

    let width = siblingComponent?.getAttribute('width');

    GridStack.setupDragIn('div[add-chart-side-panel] .grid-stack-item', {
      appendTo: 'body',
      helper: (event: Event) => {
        if (!this.args.report.hasCharts) {
          return document.createElement('div');
        }
        return (
          handleChartTemplateDragEvent(
            event,
            width,
            [
              ...this.reportingTemplates.chartTemplates,
              ...this.reportingTemplates.componentTemplates,
            ],
            this.intl,
          ) || document.createElement('div')
        );
      },
    });
  }

  get shouldShowCreateNewChart() {
    if (this.args.isStandalone) {
      return this.reportingStandalone.supportsFeature('createNewChart');
    }
    return true;
  }

  get canSeeNewChartLayout() {
    return this.appService.app.canSeeNewChartLayout;
  }

  get createNewChartButtonTitle() {
    if (this.canSeeNewChartLayout) {
      return this.intl.t(`reporting.custom-reports.report.create-new-chart`);
    } else {
      return this.intl.t(`reporting.custom-reports.report.build-from-scratch`);
    }
  }

  get createNewChartButtonDescription() {
    if (this.canSeeNewChartLayout) {
      return this.intl.t(`reporting.custom-reports.report.create-new-chart-description`);
    } else {
      return this.intl.t(`reporting.custom-reports.report.build-from-scratch-description`);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::AddChartSidePanel': typeof AddChartSidePanel;
    'reporting/custom/report/add-chart-side-drawer': typeof AddChartSidePanel;
  }
}

/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { sortBy } from 'underscore';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { cached } from 'tracked-toolbox';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { all } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import {
  convert,
  updateDataConfig,
} from 'embercom/lib/reporting/flexible/table-datarequest-builder';
import TableDataResponseMapper from 'embercom/lib/reporting/flexible/table-dataresponse-mapper';
import { copy } from 'ember-copy';
import { processRatioPercentageMetricResponses } from 'embercom/lib/reporting/chart-data-resource-compatible-helper';
import { isPresent, isEmpty } from '@ember/utils';
import ENV from 'embercom/config/environment';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import {
  shouldConvertNullsToZeros,
  shouldAllowZeroValues,
} from 'embercom/lib/reporting/flexible/data-response-helpers';
import { TEAM_TEAMMATE_GROUPING_MULTIMETRIC } from 'embercom/models/reporting/custom/visualization-options';
import { TERMS_GROUPING_MULTIMETRIC } from 'embercom/models/reporting/custom/visualization-options';
import { getOwner } from '@ember/application';
import TableColumnBuilder from 'embercom/lib/reporting/custom/table-column-builder';
import ChartSeries from 'embercom/models/reporting/custom/chart-series';

function and(...filters) {
  return {
    type: 'and',
    filters: filters.flatMap((f) => (f.type === 'and' ? f.filters : f)),
  };
}

const DEFAULT_ROWS_VISIBLE = 20;

export default class TableComponent extends Component {
  @service appService;
  @service csv;
  @service intercomEventService;
  @service notificationsService;
  @service store;
  @service intl;
  @service reportingChartService;

  @tracked tableData = [];
  @tracked numberOfRowsVisible = this.viewConfig.rowsVisible || DEFAULT_ROWS_VISIBLE;
  @tracked isLoading = true;
  @tracked sortState = this.viewConfig.sortState;
  @tracked columns = this.args.columns;

  viewConfig = this.args.viewConfig;

  get app() {
    return this.appService.app;
  }

  get targetValue() {
    return this.args.renderableChart?.visualizationOptions?.target?.value;
  }

  get canLoadMore() {
    return this.filteredTableData.length > this.numberOfRowsVisible;
  }

  get visibleTableData() {
    return this.filteredTableData.slice(0, this.numberOfRowsVisible);
  }

  get filteredTableData() {
    let { isVisibleRowAfterLoading } = this.args.viewConfig;
    return isVisibleRowAfterLoading
      ? this.tableData.filter(isVisibleRowAfterLoading)
      : this.tableData;
  }

  get globalFilters() {
    return this.args.filters || {};
  }

  get timeRange() {
    return this.args.range;
  }

  get shouldShowEmptyState() {
    return (
      this.args.dashboard?.isPaywalled ||
      this.args.isPaywalled ||
      (this.isLoading === false &&
        (this.visibleTableData.length === 0 ||
          (this.args.firstColumnDoesNotContainData && this._otherColumnsAreEmpty())))
    );
  }

  @action
  onLoadMoreRows() {
    this.numberOfRowsVisible += this.viewConfig.rowsVisible || DEFAULT_ROWS_VISIBLE;
  }

  @task({ restartable: true })
  *_loadAllData() {
    if (this.args.dashboard?.isPaywalled) {
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    let childTasks = [];

    let updatedDataConfig = updateDataConfig({
      dataConfig: this.args.dataConfig,
      filters: this.globalFilters,
      timeRange: this.timeRange,
    });

    let dataRequests = convert(updatedDataConfig, this.args.renderableChart);

    for (let dataRequest of dataRequests) {
      childTasks.push(this.fetchData.perform(dataRequest));
    }

    let rawResponses = yield all(childTasks);
    let responses = processRatioPercentageMetricResponses(
      rawResponses,
      this.args.renderableChart,
      true,
    );

    if (isPresent(this.args.renderableChart) && !this.args.renderableChart.isBespoke) {
      // the sortColumn should be the group column for Time grouping but it should be the first metric otherwise
      // paging should be visible when viewing by a term in a multimetric chart
      this.viewConfig = this.args.renderableChart.updateViewConfig(this.viewConfig);
      this.sortState = this.viewConfig.sortState;
    }

    if (!responses.includes(undefined)) {
      this.tableData = this._convertToTableData(responses);
      // generic method that includes all pre-processing of data returned from the server and mapped
      // to the table input format but before it gets to rendering
      // this includes filtering some unwanted rows or merging rows with front-end defined ones
      let isScrollable = this.viewConfig.scrollable;
      if (this.viewConfig.reloadRowsVisible) {
        this.numberOfRowsVisible = this.viewConfig.rowsVisible;
      }
      if (isScrollable) {
        this.numberOfRowsVisible = this.tableData.length;
      }
      let tableDataIsSegmented = updatedDataConfig.rows[0]?.nestedGroupData;
      if (tableDataIsSegmented) {
        this.columns = this._updateColumnsForSegmentedTable(this.args.columns, this.tableData);
        this.columns = [...this.columns];
      } else if (isPresent(this.args.renderableChart) && this.args.renderableChart.isMultimetric) {
        let columns = new TableColumnBuilder(
          this.args.renderableChart,
          getOwner(this),
        ).buildColumns();
        this.columns = [...columns];
      } else {
        this.columns = this.args.columns;
      }

      this.tableData = this._processRows({
        tableData: this.tableData,
        viewConfig: this.viewConfig,
      });
      // performs front-end based calculations for columns
      this.tableData = this._calculateCustomValues(this.tableData);
      this._sort();
      // multimetric responses can have different set of buckets.
      // we request the first top X for the first metric but request unlimited buckets for subsequent metrics
      // this allows us to get all the buckets correctly filled with data (if any)
      // then here we slice the results to get the top X based on the display limit
      if (
        isPresent(this.args.renderableChart) &&
        this.args.renderableChart.isMultimetric &&
        !this.args.renderableChart.isBespoke &&
        !this.args.renderableChart.isBrokenDownByTime &&
        (this.args.renderableChart.supportsFeature(TEAM_TEAMMATE_GROUPING_MULTIMETRIC) ||
          this.args.renderableChart.supportsFeature(TERMS_GROUPING_MULTIMETRIC))
      ) {
        let limit = this.args.renderableChart.visualizationOptions?.showSummaryRow
          ? this.args.renderableChart.viewByDisplayLimit + 1
          : this.args.renderableChart.viewByDisplayLimit;
        this.tableData = this.tableData.slice(0, limit);
      }
      this.isLoading = false;
      if (this.args.setTableData) {
        this.args.setTableData(this.tableData);
      }
    }
  }

  @task({ enqueue: true, maxConcurrency: 12 })
  *fetchData(dataRequest) {
    try {
      return yield ajax({
        url: '/ember/reporting/signal_flexible',
        type: 'POST',
        data: JSON.stringify({
          app_id: this.app.id,
          admin_id: this.app.currentAdmin.id,
          ...dataRequest,
          ...(this.reportingChartService.useSyntheticData
            ? { use_synthetic_data: this.reportingChartService.useSyntheticData }
            : {}),
        }),
      });
    } catch (error) {
      if (
        error.jqXHR &&
        error.jqXHR.status === 422 &&
        error.jqXHR.responseJSON.error === 'bucket_limit_exceeded'
      ) {
        this._notifyErrorMsg(
          this.intl.t('components.reporting.flexible.chart-error.bucket-limit-exceeded', {
            htmlSafe: true,
          }),
        );
      } else if (
        error.jqXHR &&
        error.jqXHR.status === 422 &&
        error.jqXHR.responseJSON.error === 'max_regex_filters_exceeded'
      ) {
        this._notifyErrorMsg(
          this.intl.t('components.reporting.flexible.chart-error.max-regex-filters-exceeded'),
        );
      } else {
        this._notifyErrorMsg(
          this.intl.t('components.reporting.flexible.chart-error.unexpected-error'),
        );
      }
    }
  }

  _notifyErrorMsg(msg) {
    debounce(
      this.notificationsService,
      this.notificationsService.notifyError,
      msg,
      ENV.APP._1000MS,
      ENV.APP._1000MS,
      true,
    );
  }

  _otherColumnsAreEmpty() {
    return this.visibleTableData.every((row) => this._rowIsEmpty(row));
  }

  _rowIsEmpty(row) {
    let column = this.args.columns[1];
    if (!column) {
      return true;
    }

    let emptyValues = shouldAllowZeroValues(column.metric, column.metricFunction)
      ? [undefined, null]
      : [0, undefined, null];

    // we only want to check the values of the row, not the groupName (e.g. Channel) and position of the row
    // we also skip any values that are used for comparison or composition (numerator & denominator for ratio/percentage metrics & previous value for time comparison)
    return Object.entries(row)
      .reject(
        ([key, _]) =>
          key === 'groupName' ||
          key === 'position' ||
          key.endsWith('_numerator') ||
          key.endsWith('_denominator') ||
          key.endsWith('_previous'),
      )
      .every(([_, value]) => emptyValues.includes(value));
  }

  _updateColumnsForSegmentedTable(columns, tableData) {
    let segmentByColumn = columns.splice(1)[0];
    let newColumnNames = this._buildColumnNamesFromTableData(tableData);

    for (let name of newColumnNames) {
      let newColumn = copy(segmentByColumn);
      let columnLabel = this._mapColumnLabel(name, newColumn);
      Object.assign(newColumn, { label: columnLabel, valuePath: name });
      columns.push(newColumn);
    }

    return columns;
  }

  _buildColumnNamesFromTableData(tableData) {
    let columnNames = tableData
      .flatMap((row) => Object.keys(row))
      .uniq()
      .filter((valuePath) => valuePath !== 'groupName');
    return this._sortOtherColumn(columnNames);
  }

  _sortOtherColumn(columnNames) {
    let otherColumnIndex = columnNames.indexOf('Other');
    if (otherColumnIndex !== -1) {
      columnNames.push(columnNames.splice(otherColumnIndex, 1)[0]);
    }
    return columnNames;
  }

  _mapColumnLabel(name, column) {
    let labelMappingFunction = column.labelFormatter;
    return labelMappingFunction ? column.labelFormatter(name) : name;
  }

  shouldShowRowSummary() {
    return this.visibleTableData?.filter((row) => row.groupName !== 'row-summary')?.length > 0;
  }

  _processRows({ tableData, viewConfig }) {
    let result = tableData;

    // merging
    if (viewConfig.mergeableRows) {
      let tableDataMap = tableData.reduce((acc, td) => {
        acc[td[viewConfig.mergeableRows.valuePath]] = td;
        return acc;
      }, {});

      let mergedMaps = Object.assign(viewConfig.mergeableRows.getRowsMap(), tableDataMap);
      result = Object.values(mergedMaps);
    }

    // filtering out
    if (viewConfig.isVisibleRow) {
      result = result.filter((td) => viewConfig.isVisibleRow(td, this.shouldShowRowSummary()));
    }

    return result;
  }

  _calculateCustomValues(tableData) {
    // find main column to use its valuePath as a row name
    let mainColumn = this.columns.find((c) => c.isMain) || this.columns.first;
    let rowNameProperty = mainColumn.valuePath;

    this.args.dataConfig.rows.forEach((r) => {
      if (!r.columnCustomFunctions) {
        return;
      }

      // insert row if it's missing in the results from the server
      let customRow = tableData.find((td) => td[rowNameProperty] === r.name);
      if (!customRow) {
        // the row name is the valuePath of the main column (see the first line of this function)
        customRow = { [rowNameProperty]: r.name };
        tableData.push(customRow);
      }
      // we don't need to pass the custom row to its custom calculation functions,
      // this is why we filter it out from all the rows
      let rowsWithoutCustom = tableData.filter((td) => td[rowNameProperty] !== r.name);

      r.columnCustomFunctions.forEach((ccf) => {
        customRow[ccf.columnName] = ccf.customFunction(rowsWithoutCustom);
      });
    });

    return tableData;
  }

  _convertToTableData(responses) {
    let rawFormatted = TableDataResponseMapper.mapToTableInput(
      responses,
      this.args.viewConfig.metrics,
      this.args.renderableChart,
    );
    return rawFormatted;
  }

  // -------- sorting --------
  @action
  onSortUpdate(valuePath) {
    let direction = 'asc';

    if (this.sortState.valuePath === valuePath && this.sortState.direction === 'asc') {
      direction = 'desc';
    }

    this.sortState = { valuePath, direction };
    this._sort();
    if (this.args.dataTestName) {
      this.intercomEventService.trackAnalyticsEvent({
        name: 'reporting_table_on_sort_update',
        object: this.args.dataTestName,
        sort_value: valuePath,
        sort_direction: direction,
        action: 'sort',
      });
    }
  }

  _sort() {
    let { regularRows: newRows, positionedRows } = this._splitPositionalAndRegularRows(
      this.tableData.slice(),
    );

    let { direction, valuePath } = this.sortState;

    // supporting artificial sort properties
    let specialSortingColumn = this.columns.find(
      (col) => col.valuePath === valuePath && col.sortingHelper,
    );
    let getSortValue = specialSortingColumn
      ? specialSortingColumn.sortingHelper
      : (row) => row[valuePath];

    // there are some metrics (like CSAT, SLA miss rate) that can have both null values and zero values
    // setting the default value always in 0 will treat them the same in the sorting function
    let sortDefaultValue = -Number.MAX_VALUE;
    newRows.sort(function (a, b) {
      // TODO implement ability to pass default sorting value per column
      let valA = isPresent(getSortValue(a)) ? getSortValue(a) : sortDefaultValue;
      let valB = isPresent(getSortValue(b)) ? getSortValue(b) : sortDefaultValue;

      switch (typeof valA) {
        case 'string':
          return valA.localeCompare(valB);
        default:
          return valA - valB;
      }
    });

    if (direction === 'desc') {
      newRows = newRows.reverse();
    }

    this.tableData.clear();
    this.tableData.addObjects(positionedRows.concat(newRows));
  }

  _splitPositionalAndRegularRows(tableData) {
    if (!this.viewConfig.rowOrder) {
      return { regularRows: tableData, positionedRows: [] };
    }

    let rowsData = tableData.reduce(
      (acc, row) => {
        let found = this.viewConfig.rowOrder.find((ro) => row[ro.valuePath] === ro.value);

        if (found) {
          row.position = found.position;
          acc.positionedRows.push(row);
          acc.positionedRows = sortBy(acc.positionedRows, 'position');
        } else {
          acc.regularRows.push(row);
        }

        return acc;
      },
      { regularRows: [], positionedRows: [] },
    );

    return { regularRows: rowsData.regularRows, positionedRows: rowsData.positionedRows };
  }
  // -------------------------

  @action
  getCellValue(column, row) {
    let value;
    if (column.aliases?.[row.groupName]) {
      value = column.aliases[row.groupName];
    } else if (column.getRowValue) {
      value = column.getRowValue(row, column);
    } else if (column.formatter) {
      value = column.formatter(row[column.valuePath]);
    } else {
      value = row[column.valuePath];
      if (column.type === 'number' && isPresent(value) && value > 0) {
        value = this.intl.formatNumber(value);
      }
    }

    let { metric, metricFunction } = column;

    let defaultValue = shouldConvertNullsToZeros(metricFunction, metric) ? 0 : '-';
    return value || defaultValue;
  }

  @action
  getRawValues(column, row) {
    if (this.args.viewConfig.showRawValues) {
      let numerator;
      let denominator;
      if (column.getRowValue && isPresent(column.metricId)) {
        // segmentBy
        // Segmented table can have only 1 metric so index is always 0
        let metricIndex = 0;
        let rowPath = row[column.valuePath];

        numerator = rowPath[`${metricIndex}-col-metric-${column.metricId}_numerator`];
        denominator = rowPath[`${metricIndex}-col-metric-${column.metricId}_denominator`];
      } else {
        // viewBy
        numerator = row[`${column.valuePath}_numerator`];
        denominator = row[`${column.valuePath}_denominator`];
      }
      if (isPresent(numerator) && isPresent(denominator) && denominator > 0) {
        let formattedNumerator = numerator;
        let formattedDenominator = denominator;
        if (column.numeratorFormatter) {
          formattedNumerator = column.numeratorFormatter(numerator);
        }
        if (column.denominatorFormatter) {
          formattedDenominator = column.denominatorFormatter(denominator);
        }
        return `${formattedNumerator}/${formattedDenominator}`;
      }
    }
    return undefined;
  }

  @action
  drillIn(column, row) {
    let value = row[column.valuePath];
    if (column.attribute.dataType === 'boolean') {
      value = !!value;
    }
    let property = column.attribute.field;
    let attribute = column.attribute.id;
    let chartSeries = this.args.renderableChart.chartSeries.firstObject;
    let chartSeriesFilters = chartSeries.filters || { type: 'and', filters: [] };
    let filterFromTableCell =
      value === 'Unknown'
        ? {
            type: 'not_exists',
            data: { attribute, property },
          }
        : {
            type: 'category',
            data: { attribute, property, values: [value] },
          };

    let chartSeriesWithExtraFilter = this.store.createFragment(ChartSeries.modelName, {
      metricId: chartSeries.metricId,
      filters: and(chartSeriesFilters, filterFromTableCell),
    });
    this.args.openUnderlyingDataModal(chartSeriesWithExtraFilter);
  }

  @cached
  get widthForValue() {
    // get the longest value for each column, so we can keep the alignment
    // consistent when rendering comparisons
    let longestValuePerColumn = {};
    this.columns.forEach((col) => {
      if (col.isMain) {
        return;
      }
      let longestValue = 1;
      this.visibleTableData.forEach((row) => {
        let value = this.getCellValue(col, row).length;
        let rawValues = this.getRawValues(col, row)?.length || 0;
        let totalLength = value + rawValues;
        if (totalLength > longestValue) {
          longestValue = totalLength;
        }
      });
      longestValuePerColumn[col.label] = sanitizeHtml(`min-width: ${longestValue + 1}ch`);
    });
    return longestValuePerColumn;
  }

  get showTooltipBlock() {
    return (
      this.args.renderableChart.shouldRenderChrome ||
      !this.args.renderableChart.reportState?.isStatic ||
      this.args.renderableChart.reportState?.id === 'csat_v2_report'
    );
  }

  get showChartDefinitionTooltip() {
    return (
      this.args.renderableChart.reportState?.id === 'csat_v2_report' ||
      !this.args.renderableChart.reportState?.isStatic
    );
  }

  get showTimeComparison() {
    return this.args.renderableChart?.showTimeComparison;
  }

  @action
  showRowSummaryStyling(row) {
    return row.groupName === 'row-summary';
  }

  @action
  getCellStyling(column, row) {
    if (!this.showTimeComparison && !this.targetValue) {
      return {};
    }

    let value = column.getRowValue ? column.getRowValue(row, column) : row[column.valuePath];

    let targetClass = this.getTargetStatus(column, value, row);
    let comparison = this.getComparisonStatus(column, value, row);

    return {
      targetClass,
      comparison,
    };
  }

  getTargetStatus(column, value, row) {
    if (
      column.isMain ||
      row.groupName === 'row-summary' ||
      isEmpty(this.targetValue) ||
      isNaN(this.targetValue)
    ) {
      return '';
    }

    if (value === '-' || value === undefined || value === null) {
      return '';
    }

    value = parseFloat(value);

    if (column.metric.improvementDirection === 'increasing') {
      return value >= this.targetValue ? 'bg-success-container' : 'bg-error-container';
    } else {
      return value <= this.targetValue ? 'bg-success-container' : 'bg-error-container';
    }
  }

  getComparisonStatus(column, value, row) {
    if (column.isMain) {
      return {};
    }

    let defaultStatus = { unchanged: true };

    if (value === '-' || value === undefined || value === null) {
      return defaultStatus;
    }

    let previousValue = column.getPreviousRowValue
      ? column.getPreviousRowValue(row, column)
      : row[column.previousValuePath];

    value = parseFloat(value);
    previousValue = parseFloat(previousValue);

    if (isNaN(previousValue)) {
      previousValue = 0;
    }

    if (value === previousValue) {
      return defaultStatus;
    }

    let improved;
    if (column.metric.improvementDirection === 'increasing') {
      improved = value > previousValue;
    } else {
      improved = value < previousValue;
    }

    let increased = value > previousValue;
    let delta = Math.abs(value - previousValue);

    if (column.formatter) {
      delta = column.formatter(delta);
    }

    return {
      improved,
      increased,
      delta,
    };
  }

  get maxHeight() {
    if (this.args.chartHeight) {
      return `${this.args.chartHeight}px`;
    }
    return this.args.maxHeight;
  }
}

/* import __COLOCATED_TEMPLATE__ from './sunburst.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ChartDataResource from 'embercom/lib/reporting/chart-data-resource';
import { useResource } from 'ember-resources';
import { isEmpty, isPresent } from '@ember/utils';
// @ts-ignore
import { interpolateRgbBasis } from 'd3-interpolate';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { restartableTask } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { action } from '@ember/object';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import Highcharts, { type Options, type SeriesOptionsType } from 'highcharts';
import type Metric from 'embercom/objects/reporting/unified/metrics/types';

// TODO localise all strings once they are confirmed
/* eslint-disable @intercom/intercom/no-bare-strings */
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Bespoke::Sunburst': typeof SunburstChart;
  }
}

interface SunburstArgs {
  renderableChart: RenderableChart;
  extraContext: {
    onSelectedTopicChanged: (topicName: string | null, subtopics: string[]) => void;
  };
}

interface SignalFlexibleResponseViewBy {
  groups: Array<{
    values: string[];
    aggregations: Array<{ name: string; values: number[] }>;
    name: string;
    type: string;
  }>;
}

interface Topic {
  name: string;
  description: string;
  subtopics: Set<string>;
}

interface TopicsHash {
  [key: string]: Topic;
}

interface SerializedSubtopic {
  topic_name: string;
  topic_description: string;
  subtopic_name: string;
}

interface HighchartsNode {
  id: string;
  parent: string;
  name: string;
  value: number;
  metricValue: number | undefined;
  color?: string;
  subtopics: HighchartsNode[];
  isSubtopic: boolean;
  isOther?: boolean;
}

const TOPICS_SERIES_NAME = 'Topics';

export default class SunburstChart extends Component<SunburstArgs> {
  @service declare appService: $TSFixMe;
  @service declare reportingMetrics: ReportingMetrics;

  @tracked topics: Record<string, Topic> = {};
  @tracked topicLimit = 20;
  @tracked subtopicLimit = 20;
  @tracked metricId = 'conversation.ai_generated_metrics.csat_score';
  @tracked currentNodeId = '';
  highchartsChart: any;

  constructor(owner: unknown, args: SunburstArgs) {
    super(owner, args);
    taskFor(this.loadTopics).perform();
  }

  colorGradient = interpolateRgbBasis([
    '#e7805c',
    '#e7805c',
    '#f0dc6d',
    '#f0dc6d',
    '#8dca97',
    '#8dca97',
  ]);
  inverseColorGradient = (value: number) => this.colorGradient(1 - value);

  dataResource = useResource(this, ChartDataResource, () => ({
    renderableChart: this.args.renderableChart,
  }));

  // This populates the topics hash with a structure like this:
  // { topicName: { subtopicName: true } }
  @restartableTask
  *loadTopics() {
    let response = (yield get('/ember/ai_insights/topics', {
      app_id: this.appService.app.id,
    })) as { topics: SerializedSubtopic[] };
    let topics = {} as TopicsHash;
    for (let entry of response.topics) {
      let topic = topics[entry.topic_name] || {
        name: entry.topic_name,
        description: entry.topic_description,
        subtopics: new Set(),
      };
      topic.subtopics.add(entry.subtopic_name);
      topics[topic.name] = topic;
    }
    this.topics = topics;
  }

  get metrics() {
    return [
      'conversation.ai_generated_metrics.csat_score',
      'conversation.ai_generated_metrics.dsat_score',
      'fin.resolution_rate',
      //'v1.new_conversations',
      //'fin.conversations_resolved.count',
      'conversation_rating.any_agent.csat',
      // 'conversation_rating.response_rate',
      // 'conversation_rating.any_agent.dsat',
      'fin.participation_rate',
      'fin.deflection_rate',
      'fin.routed_to_team_rate',
      //'fin.conversations_routed_to_team.count',
    ].map((metricId) => ({
      text: this.metricNameOverride(this.reportingMetrics.getMetricById(metricId)),
      value: metricId,
    }));
  }

  get secondMetric(): Metric {
    return this.args.renderableChart.metrics[1];
  }

  metricNameOverride(metric: Metric) {
    switch (metric.id) {
      case 'conversation.ai_generated_metrics.csat_score':
        return 'AI CSAT';
      case 'conversation.ai_generated_metrics.dsat_score':
        return 'AI DSAT';
      default:
        return metric.name;
    }
  }

  get currentSunburstLevel() {
    return Number.parseInt(this.currentNodeId.split('.')[0], 10) || 0;
  }

  get currentTopicName() {
    if (isPresent(this.currentNodeId)) {
      return this.highchartsChart.series[0].nodeMap[this.currentNodeId].name;
    }
    return '';
  }

  @action
  updateChartReference() {
    // The chart changes every time the metric is changed, so we need to find the chart by the series name
    this.highchartsChart = Highcharts.charts.find(
      (chart) => chart && chart.series[0].name === TOPICS_SERIES_NAME,
    );
  }

  get chartData() {
    if (isEmpty(this.topics)) {
      return [];
    }

    if (isPresent(this.dataResource.rawChartData)) {
      // parse out the two data responses and combine them

      // First gather all the data for each series into a hash with the structure:
      // {
      //   [topicName]: {
      //     name: string,
      //     value: number, // sum of the volumes for the topic
      //     subtopics: {
      //       [subtopicName]: {
      //         name: string,
      //         value: number,  // value from the first series (volume)
      //         metricValue: number,  // value from the second series (metric)
      //       },
      //       ...
      //     },
      //   },
      //   ...
      // }
      let topicHierarchy = this.dataResource.rawChartData.reduce(
        (acc: Record<string, any>, response: SignalFlexibleResponseViewBy, seriesIndex: number) => {
          response.groups[0].values.forEach((topicSubtopicPair, topicSubtopicPairIndex) => {
            // TODO: the toString() is a hack to allow tests to pass, we should just write the correct needed tests
            let [topicName, subtopicName] = topicSubtopicPair.toString().split(':');
            // ignore subtopics that don't belong to the topic
            if (this.topics[topicName]?.subtopics.has(subtopicName)) {
              acc[topicName] ||= {
                name: topicName,
                value: undefined,
                subtopics: {},
              };
              // The first series is the volume
              if (seriesIndex === 0) {
                // sum the volume for the topic
                let subtopicValue =
                  response.groups[0].aggregations[0].values[topicSubtopicPairIndex];
                if (subtopicValue) {
                  acc[topicName].value ||= 0;
                  acc[topicName].value += subtopicValue;
                }

                acc[topicName].subtopics[subtopicName] = {
                  name: subtopicName,
                  value: subtopicValue,
                };
              } else {
                // The second series is the metric
                acc[topicName].subtopics[subtopicName].metricValue =
                  response.groups[0].aggregations[0].values[topicSubtopicPairIndex];
              }
            }
          });
          return acc;
        },
        {},
      );

      // Limit to the top N topics, map it to the format needed for highcharts sunburst chart
      // {
      //   id: '0.0',
      //   parent: '',
      //   name: 'TOP_LEVEL',
      // },
      // {
      //   id: '1.1',
      //   parent: '0.0',
      //   name: 'Topic 1',
      //   value: 123,
      //   metricValue: 0.5,
      // },
      // ...
      let topTopics: HighchartsNode[] = Object.values(topicHierarchy)
        .sort((topicA: any, topicB: any) => topicB.value - topicA.value)
        .slice(0, this.topicLimit)
        .map((topic: any, index: number) => {
          let subtopics: any[] = Object.values(topic.subtopics);
          return {
            id: `1.${index + 1}`,
            parent: '0.0',
            name: topic.name,
            value: topic.value,
            metricValue: this.rollupSubtopicValues(subtopics),
            subtopics,
            isSubtopic: false,
          };
        });

      // limit the remaining topics to have limited subtopics and an 'Other' node
      let allSubtopics: HighchartsNode[] = topTopics.reduce((acc: any, topic: any) => {
        // Sort by volume, descending, with undefined metric values at the end
        let subtopics: any[] = Object.values(topic.subtopics).sort(
          (subtopicA: any, subtopicB: any) => {
            if (subtopicA.metricValue === undefined) {
              return 1;
            }
            if (subtopicB.metricValue === undefined) {
              return -1;
            }
            return subtopicB.value - subtopicA.value;
          },
        );

        // Limit the number of subtopics, and add an 'Other' node
        if (subtopics.length > this.subtopicLimit) {
          let otherSubtopics = subtopics.slice(this.subtopicLimit);

          let otherSubtopicsValue = otherSubtopics.reduce(
            (acc: number, subtopic: any) => acc + Number(subtopic.value),
            0,
          );
          // Create an 'Other' node, that rolls up the values of the subtopics it contains
          let otherSubtopic = {
            name: `${otherSubtopics.length} other subtopics`,
            value: otherSubtopicsValue,
            metricValue: this.rollupSubtopicValues(subtopics),
            color: 'rgba(180,180,180,1.0)',
            isOther: true,
          };
          // Limit the number of subtopics
          subtopics = subtopics.slice(0, this.subtopicLimit);
          subtopics.push(otherSubtopic);
        }

        // convert the subtopics to the format needed for highcharts
        return acc.concat(
          subtopics.map((subtopic: any, index: number) => {
            return {
              id: `${topic.id}.${index + 1}`,
              parent: topic.id,
              name: subtopic.name,
              value: subtopic.value,
              metricValue: subtopic.metricValue,
              color: subtopic.color,
              isSubtopic: true,
              isOther: subtopic.isOther,
            };
          }),
        );
      }, []);

      let data = [...topTopics, ...allSubtopics];
      // Update all the colors, making the scale relative to the min and max values
      // making the gradient more obvious
      let maxValue = 0;
      let minValue = 1;
      data
        .filter((node) => node.metricValue !== undefined)
        .forEach((node) => {
          maxValue = Math.max(maxValue, node.metricValue!);
          minValue = Math.min(minValue, node.metricValue!);
        });
      let colorGradient =
        this.secondMetric.improvementDirection === 'increasing'
          ? this.colorGradient
          : this.inverseColorGradient;

      data.forEach((node) => {
        if (node.metricValue !== undefined) {
          node.color ||= colorGradient((node.metricValue - minValue) / (maxValue - minValue));
        } else {
          // specific color for undefined metricvalues
          node.color = 'rgba(210,210,210,1.0)';
        }
      });

      // sort the data so items are ordered by descending metricValue, with the 'Other' nodes at the end
      data.sort((a, b) => {
        if (b.isOther || b.metricValue === undefined) {
          return -1;
        }
        if (a.isOther || a.metricValue === undefined) {
          return 1;
        }
        return b.metricValue - a.metricValue;
      });

      return [
        {
          id: '0.0',
          parent: '',
          name: 'Topics',
          color: 'rgba(255,255,255,0)',
        },
        ...data,
      ];
    } else {
      return [];
    }
  }

  rollupSubtopicValues(subtopics: Record<'value' | 'metricValue', number>[]) {
    if (subtopics.every((subtopic) => subtopic.metricValue === undefined)) {
      return undefined;
    }

    if (this.secondMetric.type === 'percentage' || this.secondMetric.type === 'ratio') {
      let sumOfValues = 0;
      let weightedSumOfValues = 0;
      subtopics
        .filter((subtopic) => subtopic.metricValue !== undefined)
        .forEach((subtopic) => {
          sumOfValues += subtopic.value;
          weightedSumOfValues += subtopic.value * subtopic.metricValue!;
        });
      return weightedSumOfValues / sumOfValues || 0;
    } else {
      return subtopics
        .filter((subtopic) => subtopic.metricValue !== undefined)
        .reduce((acc, subtopic) => acc + subtopic.metricValue!, 0);
    }
  }

  @action
  onTopicClick(event: any) {
    let currentRootNode = event.point.series.rootNode;
    let pointId = event.point.id;
    // Ignore clicks on subtopics, which have ids like '1.1.1'
    if (pointId.split('.').length <= 2) {
      let newNodeId = currentRootNode === pointId ? '0.0' : pointId;
      this.changeNodeTo(newNodeId);
    }
  }

  @action
  goBackToTopics() {
    this.changeNodeTo('0.0', true);
  }

  get chartOptions(): Options {
    return {
      chart: {
        height: 590,
        width: 590,
      },
      plotOptions: {
        series: {
          turboThreshold: 5000,
          colorKey: 'metricValue',
        },
      },
    };
  }

  get highchartsSeries(): SeriesOptionsType[] {
    let secondMetricName = this.metricNameOverride(this.secondMetric);
    let component = this;
    let formatTooltipValue = (value: number | undefined) => {
      if (value === undefined) {
        return 'no data';
      } else if (this.secondMetric.type === 'percentage') {
        return `${value.toFixed(2)}%`;
      } else {
        return value.toFixed(2);
      }
    };
    return [
      {
        type: 'sunburst',
        name: TOPICS_SERIES_NAME,
        data: this.chartData,
        breadcrumbs: {
          floating: true,
        },
        allowTraversingTree: true,
        animation: false,
        borderWidth: 1,
        borderRadius: 0,
        cursor: 'pointer',
        colorByPoint: true,
        dataLabels: {
          rotationMode: 'circular', // 'perpendicular',
          style: {
            color: 'black',
            textOutline: '0px white',
            fontWeight: 'normal',
          },
        },
        events: {
          click: this.onTopicClick.bind(this),
          afterAnimate: this.onAfterAnimate.bind(this),
        },
        levels: [
          {
            level: 1,
            levelSize: {
              unit: 'pixels',
              value: 30,
            },
            dataLabels: {
              enabled: false,
            },
          },
          {
            level: 2,
            dataLabels: {
              enabled: true,
            },
            levelSize: {
              unit: 'pixels',
              value: 150,
            },
          },
          {
            level: 3,
            dataLabels: {
              enabled: true,
              // eslint-disable-next-line object-shorthand
              formatter: function (this: any): any {
                let isSubtopicLevel = this.series.chart.breadcrumbs.level > 0;
                return isSubtopicLevel ? this.key : null;
              },
            },
          },
        ],
        tooltip: {
          headerFormat: '',
          // eslint-disable-next-line object-shorthand
          pointFormatter: function (this: any): string {
            let point = this;
            let typeLabel = point.isSubtopic ? 'Subtopic' : 'Topic';
            let topicName = point.name;
            let description;
            if (!point.isSubtopic) {
              description = component.topics[topicName]?.description;
            }

            let value =
              `<b>${typeLabel}:</b> ${topicName}<br>` +
              `<b>Conversations:</b> ${point.value}<br>` +
              `<b>${secondMetricName}:</b> ${formatTooltipValue(point.metricValue)}`;
            if (description) {
              value += `<br>${description}`;
            }
            return value;
          },
        },
      },
    ];
  }

  @action
  onTopicLimitChange(event: any) {
    this.topicLimit = event.target.value;
  }

  @action
  onSubtopicLimitChange(event: any) {
    this.subtopicLimit = event.target.value;
  }

  @action
  metricSelected(value: string) {
    this.metricId = value;
    // update the second metric inplace, triggering a re-render
    this.args.renderableChart.chartSeries.objectAt(1).metricId = value;
  }

  @action
  onAfterAnimate() {
    this.updateChartReference();
    this.changeNodeTo('0.0');
  }

  changeNodeTo(nodeId: string, drillUp = false) {
    // When drilling down, make the topics smaller and the subtopics larger
    if (nodeId === '0.0') {
      this.highchartsChart.series[0].options.levels[1].levelSize = {
        unit: 'pixels',
        value: 150,
      };
    } else {
      this.highchartsChart.series[0].options.levels[1].levelSize = {
        unit: 'pixels',
        value: 50,
      };
    }

    if (drillUp) {
      this.highchartsChart.series[0].drillUp();
    }
    this.currentNodeId = nodeId;

    let topicName = nodeId === '0.0' ? null : this.highchartsChart.series[0].nodeMap[nodeId].name;
    let subtopics = Array.from(this.topics[topicName]?.subtopics || []);

    this.args.extraContext.onSelectedTopicChanged(topicName, subtopics);
  }
}

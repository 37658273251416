/* import __COLOCATED_TEMPLATE__ from './banner-component.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Chart from 'embercom/models/reporting/custom/chart';

interface Signature {
  Args: {
    chart: Chart;
    deleteChart: () => void;
    editMode: boolean;
  };
}

export default class BannerComponent extends Component<Signature> {
  @tracked title = this.args.chart.titleForDisplay;
  @tracked color = this.args.chart.componentColor || 'bg-neutral-container';
  @tracked isHovered = false;
  @service declare store: Store;

  @action
  updateTitle(event: InputEvent) {
    this.title = (event.target as HTMLInputElement).value;
    this.args.chart.description = this.title;
    this.updateVisualizationOptions();
  }

  @action
  updateColor(bgClass: string) {
    this.color = bgClass;
    this.updateVisualizationOptions();
  }

  updateVisualizationOptions() {
    this.args.chart.visualizationOptions = this.store.createFragment(
      'reporting/custom/visualization-options',
      {
        component: {
          title: this.title,
          color: this.color,
        },
      },
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Bespoke::BannerComponent': typeof BannerComponent;
    'reporting/bespoke/banner-component': typeof BannerComponent;
  }
}

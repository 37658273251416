/* import __COLOCATED_TEMPLATE__ from './chart-type-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { VIZ_TYPE_TO_ICON_MAPPING } from 'embercom/models/reporting/custom/visualization-options';

interface Args {
  selected: any;
  analyticsObject: string;
  setSelected: (chartType: keyof typeof VISUALIZATION_TYPES) => void;
}

export const VISUALIZATION_TYPES = {
  AREA: 'area',
  BAR: 'bar',
  BANNER: 'banner',
  COLUMN_LINE: 'column-line',
  COUNTER: 'counter',
  DONUT: 'donut',
  LINE: 'line',
  HORIZONTAL_BAR: 'horizontal-bar',
  HEATMAP: 'heatmap',
  TABLE: 'tabular',
};

export default class ChartTypeFilter extends Component<Args> {
  @service intercomEventService: any;
  @service intl: any;
  @service appService: any;

  get label() {
    return this.selectionItems.find((obj) => obj.value === this.args.selected)?.text;
  }

  get selectionItems() {
    return [
      {
        text: this.intl.t('reporting.custom-reports.chart.sidebar-filters.visualizations.any'),
        value: 'any',
        icon: 'chart',
      },
      ...Object.values(VISUALIZATION_TYPES)
        .map((type) => {
          return {
            text: this.intl.t(
              `reporting.custom-reports.chart.sidebar-filters.visualizations.${type}`,
            ),
            value: type,
            icon: VIZ_TYPE_TO_ICON_MAPPING[type],
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text)),
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::ChartTypeFilter': typeof ChartTypeFilter;
  }
}

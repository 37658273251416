/* import __COLOCATED_TEMPLATE__ from './office-hours-variant.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class OfficeHoursBotInboxModifier extends Component {
  @service reportingMetrics;
  @service intl;

  @action
  toggleOfficeHours() {
    this.args.selectMetric(this.metricIdToUse);
  }

  get metricIdToUse() {
    return this.args.currentMetric.id === this.currentMetricDefaultId
      ? this.currentMetricVariantId
      : this.currentMetricDefaultId;
  }

  get currentMetricVariantId() {
    return this.reportingMetrics
      .getMetricsByFamilyId(this.args.currentMetric.metricFamilyId)
      .find((metric) => !metric.metricFamilyDefault).id;
  }

  get currentMetricDefaultId() {
    return this.reportingMetrics
      .getMetricsByFamilyId(this.args.currentMetric.metricFamilyId)
      .find((metric) => metric.metricFamilyDefault).id;
  }

  get tooltip() {
    let href = 'https://app.intercom.com/a/apps/_/settings/office-hours';
    return this.args.currentMetric.source === 'conversation'
      ? this.intl.t('reporting.custom-reports.chart.office-hours-variant.tooltip', {
          href,
          htmlSafe: true,
        })
      : this.intl.t('reporting.custom-reports.chart.office-hours-variant.teammate-tooltip', {
          href,
          htmlSafe: true,
        });
  }
}

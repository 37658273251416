/* import __COLOCATED_TEMPLATE__ from './visualization-chart-option.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  Element: HTMLElement;
  Args: {
    onClick: () => void;
    chartOption: string;
    title: string;
    description?: string;
    toggleValue: boolean;
  };
}

export default class VisualizationChartOption extends Component<Args> {
  @service declare appService: any;

  get canSeeNewChartLayout() {
    return this.appService.app.canSeeNewChartLayout;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::VisualizationChartOption': typeof VisualizationChartOption;
    'reporting/custom/chart-builder/visualization-chart-option': typeof VisualizationChartOption;
  }
}

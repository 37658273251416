/* import __COLOCATED_TEMPLATE__ from './learn-button.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class LearnButton extends Component {
  @service declare intercomEventService: $TSFixMe;

  @action trackDropdownClick(name: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: name,
      place: 'learn-dropdown',
      section: 'reporting',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::InContext::StaticReport::LearnButton': typeof LearnButton;
    'reporting/in-context/static-report/learn-button': typeof LearnButton;
  }
}
